/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Dapper API
 * Public Documentation Dapper
 * OpenAPI spec version: v1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { Error } from "../../../domain/models/error";
import type { GroupedComponents } from "../../../domain/models/groupedComponents";
import type { NotificationList200 } from "../../../domain/models/notificationList200";
import type { NotificationListDetail } from "../../../domain/models/notificationListDetail";
import type { NotificationListParams } from "../../../domain/models/notificationListParams";
import type { NotificationPreferencesCreateBody } from "../../../domain/models/notificationPreferencesCreateBody";
import type { NotificationResponseData } from "../../../domain/models/notificationResponseData";
import type { NotificationsUserPreferences } from "../../../domain/models/notificationsUserPreferences";
import { apiClient } from "../../../../lib/api-client/api-client";
import type {
  ErrorType,
  BodyType,
} from "../../../../lib/api-client/api-client";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> =
  (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const notificationList = (
  params?: NotificationListParams,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<NotificationList200>(
    { url: "/notification/", method: "GET", params },
    options,
  );
};

export const getNotificationListQueryKey = (
  params?: NotificationListParams,
) => {
  return ["/notification/", ...(params ? [params] : [])] as const;
};

export const getNotificationListInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof notificationList>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationList>>
  > = () => notificationList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationList>>
>;
export type NotificationListInfiniteQueryError = ErrorType<unknown>;

export const useNotificationListInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof notificationList>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationListInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationList>>
  > = () => notificationList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationList>>
>;
export type NotificationListQueryError = ErrorType<unknown>;

export const useNotificationList = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationList>>
  > = () => notificationList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationListSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationList>>
>;
export type NotificationListSuspenseQueryError = ErrorType<unknown>;

export const useNotificationListSuspense = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationListSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationComponentsGroupedList = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<GroupedComponents[]>(
    { url: "/notification/components/grouped/", method: "GET", signal },
    options,
  );
};

export const getNotificationComponentsGroupedListQueryKey = () => {
  return ["/notification/components/grouped/"] as const;
};

export const getNotificationComponentsGroupedListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationComponentsGroupedListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationComponentsGroupedList>>
  > = ({ signal }) => notificationComponentsGroupedList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationComponentsGroupedListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationComponentsGroupedList>>
>;
export type NotificationComponentsGroupedListQueryError = ErrorType<unknown>;

export const useNotificationComponentsGroupedList = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationComponentsGroupedListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationComponentsGroupedListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationComponentsGroupedListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationComponentsGroupedList>>
  > = ({ signal }) => notificationComponentsGroupedList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationComponentsGroupedListSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationComponentsGroupedList>>
>;
export type NotificationComponentsGroupedListSuspenseQueryError =
  ErrorType<unknown>;

export const useNotificationComponentsGroupedListSuspense = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof notificationComponentsGroupedList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationComponentsGroupedListSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationComponentsGroupedRead = (
  id: string,
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<GroupedComponents>(
    { url: `/notification/components/grouped/${id}/`, method: "GET", signal },
    options,
  );
};

export const getNotificationComponentsGroupedReadQueryKey = (id: string) => {
  return [`/notification/components/grouped/${id}/`] as const;
};

export const getNotificationComponentsGroupedReadQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationComponentsGroupedReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationComponentsGroupedRead>>
  > = ({ signal }) =>
    notificationComponentsGroupedRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationComponentsGroupedReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationComponentsGroupedRead>>
>;
export type NotificationComponentsGroupedReadQueryError = ErrorType<unknown>;

export const useNotificationComponentsGroupedRead = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationComponentsGroupedReadQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationComponentsGroupedReadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationComponentsGroupedReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationComponentsGroupedRead>>
  > = ({ signal }) =>
    notificationComponentsGroupedRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationComponentsGroupedReadSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationComponentsGroupedRead>>
>;
export type NotificationComponentsGroupedReadSuspenseQueryError =
  ErrorType<unknown>;

export const useNotificationComponentsGroupedReadSuspense = <
  TData = Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationComponentsGroupedRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationComponentsGroupedReadSuspenseQueryOptions(
    id,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create a notification preference
 */
export const notificationPreferencesCreate = (
  notificationPreferencesCreateBody: BodyType<NotificationPreferencesCreateBody>,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<NotificationsUserPreferences>(
    {
      url: "/notification/preferences",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: notificationPreferencesCreateBody,
    },
    options,
  );
};

export const getNotificationPreferencesCreateMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPreferencesCreate>>,
    TError,
    { data: BodyType<NotificationPreferencesCreateBody> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationPreferencesCreate>>,
  TError,
  { data: BodyType<NotificationPreferencesCreateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationPreferencesCreate>>,
    { data: BodyType<NotificationPreferencesCreateBody> }
  > = (props) => {
    const { data } = props ?? {};

    return notificationPreferencesCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationPreferencesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationPreferencesCreate>>
>;
export type NotificationPreferencesCreateMutationBody =
  BodyType<NotificationPreferencesCreateBody>;
export type NotificationPreferencesCreateMutationError = ErrorType<void>;

export const useNotificationPreferencesCreate = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPreferencesCreate>>,
    TError,
    { data: BodyType<NotificationPreferencesCreateBody> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getNotificationPreferencesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationPreferencesComponentsList = (
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<NotificationsUserPreferences[]>(
    { url: "/notification/preferences/components/", method: "GET", signal },
    options,
  );
};

export const getNotificationPreferencesComponentsListQueryKey = () => {
  return ["/notification/preferences/components/"] as const;
};

export const getNotificationPreferencesComponentsListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationPreferencesComponentsListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationPreferencesComponentsList>>
  > = ({ signal }) =>
    notificationPreferencesComponentsList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationPreferencesComponentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationPreferencesComponentsList>>
>;
export type NotificationPreferencesComponentsListQueryError =
  ErrorType<unknown>;

export const useNotificationPreferencesComponentsList = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationPreferencesComponentsListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationPreferencesComponentsListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationPreferencesComponentsListQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationPreferencesComponentsList>>
  > = ({ signal }) =>
    notificationPreferencesComponentsList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationPreferencesComponentsListSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationPreferencesComponentsList>>
  >;
export type NotificationPreferencesComponentsListSuspenseQueryError =
  ErrorType<unknown>;

export const useNotificationPreferencesComponentsListSuspense = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof notificationPreferencesComponentsList>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationPreferencesComponentsListSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationPreferencesComponentsRead = (
  id: string,
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<NotificationsUserPreferences>(
    {
      url: `/notification/preferences/components/${id}/`,
      method: "GET",
      signal,
    },
    options,
  );
};

export const getNotificationPreferencesComponentsReadQueryKey = (
  id: string,
) => {
  return [`/notification/preferences/components/${id}/`] as const;
};

export const getNotificationPreferencesComponentsReadQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationPreferencesComponentsReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>
  > = ({ signal }) =>
    notificationPreferencesComponentsRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationPreferencesComponentsReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>
>;
export type NotificationPreferencesComponentsReadQueryError =
  ErrorType<unknown>;

export const useNotificationPreferencesComponentsRead = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationPreferencesComponentsReadQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationPreferencesComponentsReadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationPreferencesComponentsReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>
  > = ({ signal }) =>
    notificationPreferencesComponentsRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationPreferencesComponentsReadSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>
  >;
export type NotificationPreferencesComponentsReadSuspenseQueryError =
  ErrorType<unknown>;

export const useNotificationPreferencesComponentsReadSuspense = <
  TData = Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationPreferencesComponentsRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getNotificationPreferencesComponentsReadSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationPreferencesPartialUpdate = (
  id: number,
  notificationsUserPreferences: BodyType<
    NonReadonly<NotificationsUserPreferences>
  >,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<NotificationsUserPreferences>(
    {
      url: `/notification/preferences/${id}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: notificationsUserPreferences,
    },
    options,
  );
};

export const getNotificationPreferencesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPreferencesPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<NotificationsUserPreferences>> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationPreferencesPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<NotificationsUserPreferences>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationPreferencesPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<NotificationsUserPreferences>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return notificationPreferencesPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationPreferencesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationPreferencesPartialUpdate>>
>;
export type NotificationPreferencesPartialUpdateMutationBody = BodyType<
  NonReadonly<NotificationsUserPreferences>
>;
export type NotificationPreferencesPartialUpdateMutationError =
  ErrorType<unknown>;

export const useNotificationPreferencesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPreferencesPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<NotificationsUserPreferences>> },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getNotificationPreferencesPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationReadAllPartialUpdate = (
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<unknown>(
    { url: "/notification/read-all", method: "PATCH" },
    options,
  );
};

export const getNotificationReadAllPartialUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
    void
  > = () => {
    return notificationReadAllPartialUpdate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationReadAllPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>
>;

export type NotificationReadAllPartialUpdateMutationError = ErrorType<Error>;

export const useNotificationReadAllPartialUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getNotificationReadAllPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationPartialUpdate = (
  id: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<NotificationListDetail>(
    { url: `/notification/${id}`, method: "PATCH" },
    options,
  );
};

export const getNotificationPartialUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPartialUpdate>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationPartialUpdate>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationPartialUpdate>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return notificationPartialUpdate(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationPartialUpdate>>
>;

export type NotificationPartialUpdateMutationError = ErrorType<Error>;

export const useNotificationPartialUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPartialUpdate>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions = getNotificationPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationRead = (
  id: number,
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<NotificationResponseData>(
    { url: `/notification/${id}/`, method: "GET", signal },
    options,
  );
};

export const getNotificationReadQueryKey = (id: number) => {
  return [`/notification/${id}/`] as const;
};

export const getNotificationReadQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationRead>>
  > = ({ signal }) => notificationRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationRead>>
>;
export type NotificationReadQueryError = ErrorType<Error>;

export const useNotificationRead = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationReadQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationReadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationRead>>
  > = ({ signal }) => notificationRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationReadSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationRead>>
>;
export type NotificationReadSuspenseQueryError = ErrorType<Error>;

export const useNotificationReadSuspense = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationReadSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
